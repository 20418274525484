@media screen and (min-width : 451px) and ( max-width : 820px)  {
    .qrReader {
        width: 65% !important;
        height: min-content;
    }
}
@media screen and ( max-width : 450px ) {
    .qrReader {
        width: 80% !important;
        height: fit-content;
    }
}
.qrReader {
    width : 40%;
    height: fit-content;
}