@import url('https://fonts.googleapis.com/css?family=Montserrat:600');
/* @import url('https://fonts.googleapis.com/css?family=Roboto:900'); */

body {
    font-family: 'Montserrat',Verdana, Geneva, Tahoma, sans-serif;
    background-color: #ECECEC;
  }
h1 {
    font-weight: bold;
    color: aqua;
}

.title-s-1 {
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 12px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  word-wrap: break-word;
}

#root {
  height: 100%;
}
.card-body {
  padding: 0;
}
.card-header {
  padding: 1.25rem 0 0 0;
}
.center-column {
  align-self: center;
  display: flex;
  flex-direction: column;
}

.l-none {
  list-style: none;
}

.btn-principal {
  margin-top: .5em;
  bottom: 0;
  width: 100%;
  height: 60px;   /* Height of the footer */
}

/* CSS */
.button-79 {
  backface-visibility: hidden;
  background: #004AAD;
  border: 0;
  /* border-radius: .375rem; */
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: 'Montserrat',Circular,Helvetica,sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  letter-spacing: -.01em;
  line-height: 1.3;
  padding: 1rem 1.25rem;
  /* position: absolute; */
  text-transform: uppercase;
  text-decoration: none;
  /* transform: translateZ(0) scale(1); */
  /* transition: transform .2s; */
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  /* bottom: 0; */
  width: 90%;
  left: 50%;
  /* transform: translate(-50%, -50%); */
}

.button-79:disabled {
  color: #787878;
  cursor: auto;
}

.card {
  min-height: 75vh;
}

.placeHolder {
  height: 13vh;
  width: 100%;
  margin: auto;
  bottom: 0;
  position: fixed;
  background-color: red;
}

@media screen and (min-width : 451px) and ( max-width : 820px)  {
  .card {
      height: 65%;
     
  }
}
@media screen and ( max-width : 450px ) {
  .card {
      height: 65%;  
  }
}

