.wrap {
    margin: auto;
    display: flex;
    z-index: 900;
    position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    width: 90%;

}

.modal-perso {
    text-align: center;
    border-radius: .5em;
    width: 100%;

}

.modal-perso .modal-perso-body {
    background-color: #fff;
    padding: 2em .5em 1em .5em;
    color: #000;
    border-radius: .5em;
}

.modal-perso .modal-perso-image {

    border-radius: 50%;
    /* padding: 11px 10px 2px; */
    position: relative;
    top: 1.5rem;
    margin: auto;
}

.modal-perso h1.title {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: .5em;
    color: #000;
}

.modal-perso p.content {
    margin-bottom: 2em;
    color: #000000;
    font-size: 16px;
    font-weight: lighter;
}

.modal-perso button.btn-action {
    font-size: 1em;
    font-weight: bold;
    background-color: #000;
    border: none;
    color: #fff;
    box-shadow: 0 0 0 2px #000 inset;
    border-radius: .25em;
    cursor: pointer;
}

.modal-cover {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  opacity: 0.5;
  z-index: 899;
  background-color: #000000;
  display: none;
}
