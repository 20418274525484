
#wrapper{
    box-sizing:border-box;
    display:flex;
    align-items:center;
    justify-content:center;

    background-color: #004AAD;
    margin-top: 2.5rem;
    margin-bottom : .5rem;
    
    height: 48px;
    width: 200px;
    border: 2px solid #004AAD;
    border-radius: 6px;

    /* shadow */
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  .my-super-cool-btn{
    position:relative;
    text-decoration:none;
    color:#fff;
    letter-spacing:1px;
    font-size:1.5rem;
    box-sizing:border-box;
    background-color: #004AAD;
    border-radius: 50%;
  }
  .my-super-cool-btn span{
    position:relative;
    box-sizing:border-box;
    display:flex;
    align-items:center;
    justify-content:center;
    width:80px;
    height:80px;
    text-transform: uppercase;
    text-shadow: 4px 6px 4px rgba(0, 0, 0, 0.25);
  }
  .my-super-cool-btn span:before{
    content:'';
    width:100%;
    height:100%;
    display:block;
    position:absolute;
    border-radius:100%;
    box-sizing:border-box;
    transition: all .85s cubic-bezier(0.25, 1, 0.33, 1);
    box-shadow: 0 30px 85px rgba(0,0,0,0.14), 0 15px 35px rgba(0,0,0,0.14);
  }
  .my-super-cool-btn:hover span:before{
    transform:scale(0.8);
    box-shadow: 0 20px 55px rgba(0,0,0,0.14), 0 15px 35px rgba(0,0,0,0.14);
  }
  .my-super-cool-btn .dots-container{
    opacity:0;
    animation: intro 1.6s;
    animation-fill-mode: forwards;
  }
  .my-super-cool-btn .dot{
    width:8px;
    height:8px;
    display:block;
    background-color:#F3CF14;
    border-radius:100%;
    position:absolute;
    transition: all .85s cubic-bezier(0.25, 1, 0.33, 1);
  }
  .my-super-cool-btn .dot:nth-child(1){
    top:50px;
    left:50px;
    transform:rotate(-140deg);
    animation: swag1-out 0.3s;
    animation-fill-mode: forwards;
    opacity:0;
  }
  .my-super-cool-btn .dot:nth-child(2){
    top:50px;
    right:50px;
    transform:rotate(140deg);
    animation: swag2-out 0.3s;
    animation-fill-mode: forwards;
    opacity:0;
  }
  .my-super-cool-btn .dot:nth-child(3){
    bottom:50px;
    left:50px;
    transform:rotate(140deg);
    animation: swag3-out 0.3s;
    animation-fill-mode: forwards;
    opacity:0;
  }
  .my-super-cool-btn .dot:nth-child(4){
    bottom:50px;
    right:50px;
    transform:rotate(-140deg);
    animation: swag4-out 0.3s;
    animation-fill-mode: forwards;
    opacity:0;
  }
  .my-super-cool-btn:hover .dot:nth-child(1){
    animation: swag1 0.3s;
    animation-fill-mode: forwards;
  }
  .my-super-cool-btn:hover .dot:nth-child(2){
    animation: swag2 0.3s;
    animation-fill-mode: forwards;
  }
  .my-super-cool-btn:hover .dot:nth-child(3){
    animation: swag3 0.3s;
    animation-fill-mode: forwards;
  }
  .my-super-cool-btn:hover .dot:nth-child(4){
    animation: swag4 0.3s;
    animation-fill-mode: forwards;
  }
  @keyframes intro {
     0% {
       opacity:0;
    }
    100% {
       opacity:1;
    }
  }
  @keyframes swag1 {
     0% {
       top:50px;
       left:50px;
       width:8px;
    }
    50% {
      width:30px;
      opacity:1;
    }
    100% {
       top:24px;
       left:24px;
       width:8px;
       opacity:1;
    }
  }
  @keyframes swag1-out {
     0% {
       top:20px;
       left:20px;
       width:8px;
    }
    50% {
       width:30px;
      opacity:1;
    }
    100% {
       top:50px;
       left:50px;
       width:8px;
      opacity:0;
    }
  }
  @keyframes swag2 {
     0% {
       top:50px;
       right:50px;
       width:8px;
    }
    50% {
      width:30px;
      opacity:1;
    }
    100% {
       top:24px;
       right:24px;
       width:8px;
       opacity:1;
    }
  }
  @keyframes swag2-out {
     0% {
       top:20px;
       right:20px;
       width:8px;
    }
    50% {
       width:30px;
      opacity:1;
    }
    100% {
       top:50px;
       right:50px;
       width:8px;
      opacity:0;
    }
  }
  @keyframes swag3 {
     0% {
       bottom:50px;
       left:50px;
       width:8px;
    }
    50% {
      width:30px;
      opacity:1;
    }
    100% {
       bottom:24px;
       left:24px;
       width:8px;
       opacity:1;
    }
  }
  @keyframes swag3-out {
     0% {
       bottom:20px;
       left:20px;
       width:8px;
    }
    50% {
       width:30px;
      opacity:1;
    }
    100% {
       bottom:50px;
       left:50px;
       width:8px;
      opacity:0;
    }
  }
  @keyframes swag4 {
     0% {
       bottom:50px;
       right:50px;
       width:8px;
    }
    50% {
      width:30px;
      opacity:1;
    }
    100% {
       bottom:24px;
       right:24px;
       width:8px;
       opacity:1;
    }
  }
  @keyframes swag4-out {
     0% {
       bottom:20px;
       right:20px;
       width:8px;
    }
    50% {
       width:30px;
      opacity:1;
    }
    100% {
       bottom:50px;
       right:50px;
       width:8px;
      opacity:0;
    }
  }
  