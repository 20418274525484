
.scangif {
    width: 45%;
    filter: drop-shadow(4px 8px 4px rgba(0, 0, 0, 0.72));
    margin: 0;
}

@media screen and (min-width : 451px) and ( max-width : 820px)  {
    .scangif {
        width: 60%;
        margin-bottom: 3rem;
       
    }
}

@media screen and ( max-width : 450px ) {
    .scangif {
        width: 80%;
        margin-bottom: .5rem;
       
    }
}
