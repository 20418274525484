input {
    height: 45px;
    padding: 10px 15px;
    margin-bottom: 15px;
}
button {
    outline: none;
    border: none;
    padding: 15px;
    cursor: pointer;
    font-size: 16px;
}
.login__container,
.signup__container,
.verify,
.dashboard {
    width: 100%;
    min-height: 100vh;
    padding: 50px 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.login__form,
.verify__form,
.signup__form {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.loginBtn,
.signupBtn,
.codeBtn {
    background-color: #004AAD;
    color: #fff;
    margin-bottom: 15px;
    border-radius: 0.25rem!important;
}
.signOutBtn {
    background-color: #c21010;
    color: #fff;
}
.link {
    cursor: pointer;
    color: #004AAD;
}
.code {
    width: 50%;
    text-align: center;
}
.verify__form {
    align-items: center;
}

@media screen and (max-width: 800px) {
    .login__container,
    .signup__container,
    .verify {
        padding: 30px;
    }
}